import React from "react";
import logo from './img/gob.svg';




export default function Cover() {
    return (
      <div className="footer">
        <div className="flex-row-nw ms1 footerg" style={{ color:"white", gap:"5%", paddingTop:"30px", paddingBottom:"65px"}}>
          <div className="footer-gob" style={{flexBasis:"25%"}}><img src={logo} /></div>
          <div className="flex-col footer-divs" style={{flexBasis:"25%"}}>
            <p style={{margin:"10px 0 20px 0", fontSize:"18px"}}>Enlaces</p>
            <a classname="footer-links" href="https://participa.gob.mx/" style={{color:'white', fontSize:'14px', textDecoration:"none"}}>Participa</a>
            <a href="https://www.gob.mx/publicaciones"style={{color:'white', fontSize:'14px', textDecoration:"none"}} >Publicaciones Oficiales</a>
            <a href="http://www.ordenjuridico.gob.mx/"style={{color:'white', fontSize:'14px', textDecoration:"none"}} >Marco Jurídico</a>
            <a href="https://consultapublicamx.inai.org.mx/vut-web/"style={{color:'white', fontSize:'14px', textDecoration:"none"}} >Plataforma Nacional de Transparencia</a>
            <a href="https://alertadores.funcionpublica.gob.mx/"style={{color:'white', fontSize:'14px', textDecoration:"none"}} >Alerta</a>
            <a href="https://sidec.funcionpublica.gob.mx/"style={{color:'white', fontSize:'14px', textDecoration:"none"}} >Denuncia</a>
          </div>
          <div className="flex-col footer-divs" style={{flexBasis:"25%", fontSize:"14px"}}>
            <p style={{margin:"10px 0 20px 0", fontSize:"18px"}}>¿Qué es gob.mx?</p>
            <p>Es el portal único de trámites, infromación y participación ciudadana. <a style={{color:'white', textDecoration:"none", fontSize:'14px'}} href="https://www.gob.mx/que-es-gobmx">Leer más</a> </p>
            <a style={{color:'white', fontSize:'14px', textDecoration:"none"}} href="https://datos.gob.mx/">Portal de datos abiertos</a>
            <a style={{color:'white', fontSize:'14px', textDecoration:"none"}} href="https://datos.gob.mx/">Declaración de accesibilidad</a>
            <a style={{color:'white', fontSize:'14px', textDecoration:"none"}} href="https://www.gob.mx/privacidadintegral">Aviso de privacidad integral</a>
            <a style={{color:'white', fontSize:'14px', textDecoration:"none"}} href="https://www.gob.mx/privacidadsimplificado">Aviso de privacidad simplificado</a>
            <a style={{color:'white', fontSize:'14px', textDecoration:"none"}} href="https://www.gob.mx/terminos">Términos y Condiciones</a>
            <a style={{color:'white', fontSize:'14px', textDecoration:"none"}} href="https://www.gob.mx/terminos#medidas-seguridad-informacion">Política de seguridad</a>
            <a style={{color:'white', fontSize:'14px', textDecoration:"none"}} href="https://www.gob.mx/sitemap">Mapa de sitio</a>
          </div>
          <div className="flex-col footer-divs" style={{flexBasis:"25%"}}>
            <a href="https://www.gob.mx/tramites/ficha/presentacion-de-quejas-y-denuncias-en-la-sfp/SFP54" style={{color:'white', fontSize:'14px', textDecoration:"none", margin:"10px 0 20px 0"}}>Denuncia contra servidores públicos</a>
            <p style={{fontSize:"18px"}}>Síguenos en</p>
            <div className="flex-row-nw iconsRedes" style={{gap:"15px"}}>
              <a style={{color:'white', fontSize:'14px', textDecoration:"none"}} href="https://www.facebook.com/gobmexico" className="social-icon facebook"></a>
              <a style={{color:'white', fontSize:'14px', textDecoration:"none"}} href="https://twitter.com/GobiernoMX" className="social-icon twitter"></a>
            </div>
          </div>
        </div>
        <div>
          <div className="footer-ban" style={{height:"50px"}}></div>
        </div>
        
      </div>
    );

}