import { Router, BrowserRouter as Switch, Route, Routes, BrowserRouter } from 'react-router-dom';

import './App.css';
import Navbar from './components/js/navbar.js';
import Footer from './components/js/footer.js';

import Home from './components/js/siged.js';
import SigedActivo from './components/js/sigedActivo.js';
import PrepaEnlinea from './components/js/prepaenlinea';
import SigedBusqueda from './components/js/sigedBusqueda.js';


function App() {
  return (
    <BrowserRouter>
    <Navbar/>
      <div style={{height:"120px"}}></div>
    
    
      <Routes>
        <Route exact path="/certificados/iems" element={<SigedActivo/>} />
        <Route exact path="/certificado/iems" element={<SigedBusqueda/>} />
        <Route exact path="/" element={<Home/>} />
      </Routes>
      
    <Footer/>
    </BrowserRouter>
  );
}

export default App;
