import React, { useState } from 'react';
import gob from './img/gob.svg';



window.addEventListener('load', function() {
    let ventanaNav1 = document.getElementById('subnav1-1');
    console.log(document.getElementById('subnav1-1'))
    
});




export default function Navbar() {
    const [count, setCount] = useState(1);
    const [count2, setCount2] = useState(1);

    const [cSubNav, setcSubNav] = useState(0);
    const [cSubNav2, setcSubNav2] = useState(0);
    

    function nav1Collpase(count = count){
        
        
        count = count + 1
        if(count%2==0){
            
            document.getElementById('nav1').style.height = "160px";
            document.getElementById('nav2').style.height = "0";
            
        }
        else{
            
            document.getElementById('nav1').style.height = "0";
            
        }
        
        
    }
    function nav2Collpase(count2 = count2){
        
        setCount(1)
        count2 = count2 + 1
        if(count2%2==0){
            
            document.getElementById('nav2').style.height = "236.3px";
            document.getElementById('nav1').style.height = "0";
            
        }
        else{
            
            document.getElementById('nav2').style.height = "0";
            
        }
        
        
    }



    function subnav1Collapse(cSubNav = cSubNav){
        const subnav1 = document.getElementById('subnav1').clientWidth
        const distancia = subnav1 + 'px'
        
        document.getElementById('subnav1-1').style.paddingRight = distancia

        setcSubNav(cSubNav + 1) 
        
        
        
        if(cSubNav%2==0){
            
            document.getElementById('subnav1-1').style.display = "flex";
            document.getElementById('subnav1-2').style.display = "none";
            setcSubNav2(0) 
            document.getElementById('nav1').style.height = "0";
            
        }if(cSubNav%2!=0){
        
            document.getElementById('subnav1-1').style.display = "none";
            
        }
    }
    function subnav2Collapse(cSubNav2 = cSubNav2){
        
       
        setcSubNav2(cSubNav2 + 1) 
        
        
        
        if(cSubNav2%2==0){
            
            document.getElementById('subnav1-2').style.display = "flex";
            document.getElementById('subnav1-1').style.display = "none";
            setcSubNav(0) 
            document.getElementById('nav2').style.height = "0";
            
        }if(cSubNav2%2!=0){
        
            document.getElementById('subnav1-2').style.display = "none";
            
        }
    }
    
    
    
   

    return (
        <div className="flex-col navg">
            
             {/* Navbar superior */}

        <div className="flex-row-nw navbar1 ps-nav1 ">
            <div className="flex-row-nw ai-c nav12" style={{justifyContent:"", flexBasis:"28%", justifyContent:"space-between", paddingLeft:"10px"}}>
                <div>
                <i className="fa fa-universal-access" style={{color:"white", fontSize:"40px"}}></i>
                </div>
            
                <img src={gob}  className="img-gob" style={{height:'40px', width:'auto'}}/>
               {/* Navbar superior--2 */}
            </div>

            <div className="flex-row nav-menu1" style={{flexBasis:"72%", justifyContent:"flex-end", alignItems:"center", display:"none"}}>
                
                <button onClick={() => {setCount(count + 1); nav1Collpase(count)} } style={{backgroundColor:"transparent", border:"none"}}>
                    <div className="icon-bar" ></div>
                    <div className="icon-bar" ></div>
                    <div className="icon-bar"></div>

                </button>
                
            </div>
            
            <div className="flex-row-nw jc-fe nav22" style={{color:"white", flexBasis:"72%"}}>
                <a href="https://www.gob.mx/tramites" className="a-white titulos flex-row-nw jc-fe" style={{padding:"0 15px 15px 15px", alignItems:"end", marginBottom:"5px", fontWeight:"300", fontSize:"100%"}}>Registro de vacunación</a>
                <a href="https://www.gob.mx/gobierno" className="a-white titulos flex-row-nw jc-fe" style={{padding:"0 15px 15px 15px", alignItems:"end", marginBottom:"5px"}}>Información sobre COVID-19</a>
                <a href="https://www.gob.mx/tramites" className="a-white titulos flex-row-nw jc-fe" style={{padding:"0 15px 15px 15px", alignItems:"end", marginBottom:"5px"}}>Trámites</a>
                <a href="https://www.gob.mx/gobierno" className="a-white titulos flex-row-nw jc-fe" style={{padding:"0 15px 15px 15px", alignItems:"end", marginBottom:"5px"}}>Gobierno</a>
                <a href="https://www.gob.mx/busqueda" className="a-white titulos flex-row-nw jc-fe fa fa-search" style={{padding:"0 15px 15px 15px", alignItems:"end", marginBottom:"5px"}}></a>
                
            </div>
            
        </div>
         {/* Navbar superior */}
        <div style={{backgroundColor:"#13322B", height:"60px"}}>
            <div className="flex-row-nw ms1" style={{height:"90%" ,alignItems: "center"}} >
                <div className="flex-row-nw navl2" style={{flexBasis:"15%", justifyContent:"flex-start"}}>
                    <a href="https://siged.sep.gob.mx/SIGED/index.html" className="" style={{fontSize:"20px", color:"white", textDecoration:"none"}}>SIGED</a>

                </div>

                


                <div className="flex-row nav-menu2" style={{flexBasis:"72%", justifyContent:"flex-end", alignItems:"center", display:"none"}}>
                    <button onClick={() => {setCount2(count2 + 1); nav2Collpase(count2)} } style={{backgroundColor:"transparent", border:"none", }}>
                        <div className="icon-bar" ></div>
                        <div className="icon-bar" ></div>
                        <div className="icon-bar"></div>

                    </button>
                </div>
                <div className="flex-row-nw nav22" style={{flexBasis:"85%", justifyContent:"flex-end"}}>
                    <a className="a-white titulos flex-row-nw" href="https://siged.sep.gob.mx/SIGED/index.html" style={{alignItems: "center", padding: "15px"}}><i className="fa fa-home" style={{padding:"0 5px 0 5px"}}></i>Inicio</a>
                    <a className="a-white titulos flex-row-nw" href="https://siged.sep.gob.mx/SIGED/index.html#ref" style={{alignItems: "center", padding:"0 15px"}}><i className="fa fa-book" style={{padding:"0 5px 0 5px"}}></i>Referencias</a>
                    <a className="a-white titulos flex-row-nw" href="https://www.planeacion.sep.gob.mx/principalescifras/" style={{alignItems: "center", padding:"0 15px"}}><i class="fa fa-chart-line" aria-hidden="true" style={{padding:"0 5px 0 5px"}}></i>Sistema Interactivo</a>
                    <button id="tablero-menu" onClick={() => subnav1Collapse(cSubNav)} className="a-white titulos flex-row-nw nav2-submenus" style={{alignItems: "center", padding:"0 15px"}}><i className="glyphicon glyphicon-signal" style={{padding:"0 5px 0 5px"}}></i>Tableros Analíticos</button>
                    <button onClick={() => subnav2Collapse(cSubNav2)} id="subnav1" className="a-white titulos flex-row-nw nav2-submenus" href="https://siged.sep.gob.mx/SIGED/documentos.html#" style={{alignItems: "center", padding:"0 15px"}}><i className="glyphicon glyphicon-exclamation-sign" style={{padding:"0 5px 0 5px"}}></i>Aviso de Privacidad</button>
                    
                </div>
            </div>

        </div>
       
        <div id="nav1" className='nav1 flex-column' style={{height:"0"}}>
            <ul style={{padding:"0"}}>
                <li className='nav1-links'><a className="a-white titulos flex-row-nw " href="https://siged.sep.gob.mx/SIGED/index.html" style={{alignItems: "center", padding: "15px"}}>Trámites</a></li>
                <li className='nav1-links'><a className="a-white titulos flex-row-nw " href="https://www.gob.mx/gobierno" style={{alignItems: "center", padding: "15px"}}>Gobierno</a></li>
                <li className='nav1-links'><a className="a-white titulos flex-row-nw " href="https://www.gob.mx/busqueda" style={{alignItems: "center", padding: "15px"}}><i className="fa fa-search" style={{padding:"0 5px 0 5px"}}></i></a></li>
            </ul>
     
        </div>

        <div id="nav2" className='nav2 flex-column' style={{height:"0px", fontSize:"12px"}}>
            <ul style={{padding:"0", listStyle:"none"}}>
                <li> <a className="a-white titulos flex-row-nw nav2-links" href="https://siged.sep.gob.mx/SIGED/index.html" style={{alignItems: "center"}}><i className="fa fa-home" style={{padding:"0 5px 0 5px"}}></i>Inicio</a></li>
                <li> <a className="a-white titulos flex-row-nw nav2-links" href="https://siged.sep.gob.mx/SIGED/index.html#ref" style={{alignItems: "center"}}><i className="fa fa-book" style={{padding:"0 5px 0 5px"}}></i>Referencias</a></li>
                <li> <a className="a-white titulos flex-row-nw nav2-links" href="https://www.planeacion.sep.gob.mx/principalescifras/" style={{alignItems: "center"}}><i class="fa fa-chart-line" aria-hidden="true" style={{padding:"0 5px 0 5px"}}></i>Sistema Interactivo</a></li>
                <li > <a className="a-white titulos flex-row-nw nav2-links" href="https://siged.sep.gob.mx/SIGED/documentos.html#" style={{alignItems: "center"}}><i className="glyphicon glyphicon-signal" style={{padding:"0 5px 0 5px"}}></i>Tableros Analíticos</a></li>
                <li> <a className="a-white titulos flex-row-nw nav2-links" href="https://siged.sep.gob.mx/SIGED/documentos.html#" style={{alignItems: "center"}}><i className="glyphicon glyphicon-exclamation-sign" style={{padding:"0 5px 0 5px"}}></i>Aviso de Privacidad</a> </li>
            </ul>
     
        </div>

        <div id="subnav1-1" className='flex-row-nw subnav1-g ms1' style={{display:'none'}} >
            <div className='subnav1 flex-column'>
            <a className=" flex-row-nw nav2-links" href="https://siged.sep.gob.mx/tableros/" style={{alignItems: "center", fontSize:"15px", padding:"0"}}><i className="glyphicon glyphicon-signal" style={{padding:"0 5px 0 5px"}}></i>Estadística Educativa</a>
            <a className=" flex-row-nw nav2-links" href="https://siged.sep.gob.mx/SIGED/estadisticas.html" style={{alignItems: "center", fontSize:"15px", padding:"0"}}><i className="fa fa-chart-bar" style={{padding:"0 5px 0 5px"}}></i>Estadística de Escuelas</a>
            </div>
        </div>

        <div id="subnav1-2" className='flex-row-nw subnav1-g ms1' style={{display:'none'}} >
            <div className='subnav1 flex-column' style={{padding:"0 10% 2px 5px"}}>
            <a className=" flex-row-nw nav2-links" href="https://siged.sep.gob.mx/SIGED/aviso_simplificado.html" style={{alignItems: "center", fontSize:"15px", padding:"0"}}><i className="glyphicon glyphicon-resize-small" style={{padding:"0 5px 0 5px"}}></i>Simplificado</a>
            <a className=" flex-row-nw nav2-links" href="https://siged.sep.gob.mx/SIGED/aviso_integral.html" style={{alignItems: "center", fontSize:"15px", padding:"0"}}><i className="glyphicon glyphicon-resize-full" style={{padding:"0 5px 0 5px"}}></i>Integral</a>
            </div>
        </div>





      </div>
    );

}