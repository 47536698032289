import React from "react";
import { useState, useEffect } from 'react';
import ban1 from './img/ban2.png'
import faTexto from './img/faTexto.png'
import datos from './datos.json'

// method: 'GET',
// url: global_uri+'/certificado/consultaCertificado/?folios='+consulta,
// url: 'http://localhost:8080/Core/alumno/curp=' + curps,
// url: 'https://siged.sep.gob.mx/services/AlumnoService/AlumnoServiceRS/alumnos/alumno/' + curps,
// headers: {
//     'Content-Type': 'application/x-www-form-urlencoded',
// }
//https://siged.sep.gob.mx/certificado/consultaCertificado/?folio=3D607306-28A3-42BF-9A6D-78E5DC832BE2

export default function Home(){

    const colNombre = "OSCAR IVAN ZAMORANO ABRAHAM";
    const colEstudios = "BACHILLERATO";
    const colDocumento = "TERMINACIÓN"
    const colFolio = "3d607306-28a3-42bf-9a6d-78e5dc832be2"
    const colAutoridad = "SERVICIO NACIONAL DE BACHILLERATO EN LÍNEA"
    const colFecha = "19/10/2018 21:01:54"
    const colPromedio = "8.0"
    const colEstatus = "REGISTRADO"


    const url = document.URL
    const urlm = document.URL.length - 37
    const urlg = url.substring(urlm) 
    const datosLong = datos.length - 1  
     
    
    var prueba = '';
    var contador = 0;
    function Buscar(){
        // if(folioBuscar == ""){
        //     folioBuscar = document.getElementById('buscaFolio').placeholder;
        //     setCount(folioBuscar)
        //     setTimeout(1000)
            
        // }
        
        if(document.getElementById('buscaFolio').value=='')
        {
            prueba = document.getElementById('buscaFolio').placeholder;

        }
        if(document.getElementById('buscaFolio').value!='')
        {
            prueba = document.getElementById('buscaFolio').value;

        }
    
        for( let i=0; i < datos.length; i++){
            
            
            if(datos[i].folio == prueba){
                
               
                window.location.href='./?folio='+prueba
                contador = 1;
                
              
                
            }
            if( i == datosLong && contador == 0){
             
                
                window.location.href= 'https://siged.sep.gob.mx.busqueda.info/certificado/iems/?folio='+prueba
            }
            
            
            
        }
    }

    function restablecerFolio(){
        document.getElementById('buscaFolio').value = ""
    }

    
    
for( let i=0; i < datos.length; i++){
    
    if(datos[i].folio == urlg){
        
        return(
            <div className="">
                {/* Primer ban */}
                <div style={{backgroundSize: "cover", height:"350px"}} className="bancentral">
                        <img src={ban1} className="siged-ban1img"></img>
                </div>
                <div className="flex-col" style={{height:"300px", justifyContent:"center", top: "100px", width:"100%", position:"absolute"}}>
                    
                    
                    <div className="ms1 flex-col" style={{flexBasis:"50%", justifyContent:"end"}}><h5 className="ban1-letra siged-movt1" style={{marginTop:"0px", fontSize:"24px"}}>Sistema de Información y Gestión Educativa</h5></div>
                    <div className="ms1" style={{flexBasis:"50%"}}><h3 className="ban1-letra siged-movt2" style={{fontSize:"28px", margin:"5px 0 0 0"}}>Validación de documentos académicos electrónicos</h3></div>            
                </div>
        
        
                {/* Cuadro input Folio */}
                <div className="flex-col cuadro-captura ms1" style={{padding:"20px 30px",top: "-65px", justifyContent:"center" ,position: "relative"}}>
                    
                    <div className="flex-row-nw siged-input" style={{gap:"25px"}}>
                        <div className="flex-col" style={{flexBasis:"50%"}}>
                            <div className="div-folio">
                                <input id="buscaFolio" class="i-folio" ng-model="folio" type="text" placeholder={urlg} style={{fontSize: "18px",background:"rgb(225,225,225)",color:"black"}} required=""/> </div>
                        </div>
                        <div className="flex-row-nw siged-input-bottons" style={{flexBasis:"50%", gap:"25px"}}>
                            <div style={{width:"100%"}} className="siged-botton"><button className="b-buscar" style={{height:"97%"}} onClick={Buscar}>Buscar</button></div>
                            <div style={{width:"100%"}} className="siged-botton"><button className="b-restablecer" style={{height:"97%"}} onClick={restablecerFolio}>Restablecer</button></div>
                        </div>
                    </div>
                    <div className="flex-col" style={{margin:"10px", alignItems:"center", fontSize:"18px"}}>
                        <a className="siged-txtfolio" href="https://siged.sep.gob.mx/SIGED/archivos/UbicaFolio.pdf" style={{textDecoration:"underline", color:"white", fontWeight:"300"}}>Ubica el folio en tu certificado</a>
                    </div>
                    
                </div>
        
                {/* Pequeña barra nav */}
                <div className="siged-navpq flex-row ms1" style={{fontSize:"18px", gap:"10px", marginBottom:"65px", flexWrap:"wrap", lineHeight:"0.9"}}>
                    <div><a style={{color:"black"}} href="https://siged.sep.gob.mx/SIGED/index.html">Inicio</a></div>
                    <div><a className="mayorque" style={{textDecoration:"none"}}></a><a  style={{color:"black"}} href="https://siged.sep.gob.mx/SIGED/index.html#info">Consultas</a></div>
                    <div><a className="mayorque" style={{textDecoration:"none"}} ></a><a style={{color:"black"}} href="https://siged.sep.gob.mx/SIGED/documentos.html">Documentos académicos electrónicos</a></div>
                    
                    
                </div>
        
        
        
                {/* Consulta de documento folio */}
        
                <div className="ms1" style={{padding:"0 12.5px 0 12.5px"}}>
                    <div>
                        <h4 style={{fontSize:"24px", fontWeight:"600", }}>Detalle del documento</h4>
                        <hr className="red"></hr>
        
                    </div>
                    <div className="flex-row">
                        <div style={{flexBasis:"25%"}} className="iconTexto">
                            <img className="sigedA-icon" src={faTexto}></img >
                        </div>
                        <div className="flex-row cuerpoTexto" style={{flexWrap:"wrap", flexBasis:"75%"}}>
                            <div className="sigedA-datos datosC" style={{paddingRight:"30px"}}>
                                <h5  style={{fontWeight:"700" , fontSize:"18px"}}>Nombre</h5>
                                <p style={{fontWeight:"300" , fontSize:"18px", textTransform:"uppercase"}}>{datos[i].nombre}</p>
                            </div>
                            <div className="sigedA-datos datosC">
                                <h5 style={{fontWeight:"700" , fontSize:"18px"}}>Carrera</h5>
                                <p style={{fontWeight:"300" , fontSize:"18px"}}>{colEstudios}</p>
                            </div>
                            <div className="sigedA-datos">
                                <h5 style={{fontWeight:"700" , fontSize:"18px"}}>Tipo de documento</h5>
                                <p style={{fontWeight:"300" , fontSize:"18px"}}>{colDocumento}</p>
                            </div>
                            <div className="sigedA-datos">
                                <h5 style={{fontWeight:"700" , fontSize:"18px"}}>Folio</h5>
                                <p style={{fontWeight:"300" , fontSize:"18px"}}>{datos[i].folio}</p>
                            </div>
        
                            <div className="sigedA-datos">
                                <h5 style={{fontWeight:"700" , fontSize:"18px"}}>Autoridad Emisora</h5>
                                <p style={{fontWeight:"300" , fontSize:"18px"}}>{colAutoridad}</p>
                            </div>
                            <div className="sigedA-datos">
                                <h5 style={{fontWeight:"700" , fontSize:"18px"}}>Fecha registro SIGED</h5>
                                <p style={{fontWeight:"300" , fontSize:"18px"}}>{datos[i].ftimbrado} {datos[i].fhora}</p>
                            </div>
                            <div className="flex-row-nw divscolor" style={{flexBasis:"40%"}}> 
                                    <div className="sigedA-promedio flex-col">
                                        <h5 style={{fontSize:"18px", fontWeight:"600", marginBottom:"12.5px"}}>Promedio:</h5>
                                        <p style={{marginBottom:"2px", fontSize:"18px"}}>{datos[i].promedio}</p>
                                        <p style={{marginTop:"0", fontSize:"18px", lineHeight:"15px", textTransform:"uppercase"}}>{datos[i].proletra}</p>
                                    </div>
        
                            </div>
                                    
                            <div className="flex-row-nw divscolor" style={{flexBasis:"50%", justifyContent: "flex-end"}}>
                                <div className="sigedA-estatus flex-col" style={{}}>
                                    <h5 style={{fontSize:"18px", fontWeight:"600", textAlign:"center"}}>Estatus del documento</h5>
                                    <p style={{fontSize:"18px", fontWeight:"600"}}>{colEstatus}</p>
        
                                </div>
                            </div>
                                
                            
                            
                            
        
        
                        </div>
        
                    </div>
        
        
                </div>
        
        
                <div className="ms1" style={{fontSize:"18px", paddingBottom:"30px"}}>
                    <hr className="red" style={{margin:"40px 0 30px 0"}}></hr>
                    <p style={{lineHeight: "22px", }}>La información de los certificados que se muestran en este portal, son el resultado de la integración de información que envían las autoridades educativas de los estados y la Ciudad de México. 
                        "En caso de que la información presentada no coincida con tus resultados obtenidos en el ciclo escolar o no esté disponible, por favor ponte en contacto con la autoridad de tu centro escolar"</p>
                    <p>La información presentada es de carácter informativa y para verificar la validez del documento impreso. Es una representación de la información contenida en el certificado (documento impreso)</p>
                    <p className="parrafoC">El documento oficial es el que la institución Educativa entrega a los padres o tutores de los educandos en sus centros escolares</p>
                    
                </div>
        
            </div>
        )
    }
    
    if(i+1 == datos.length){
        console.log('No encontrado')
        return(
            <div className="">
                {/* Primer ban */}
                <div style={{backgroundSize: "cover", height:"350px"}} className="bancentral">
                        <img src={ban1} className="siged-ban1img"></img>
                </div>
                <div className="flex-col" style={{height:"300px", justifyContent:"center", top: "100px", width:"100%", position:"absolute"}}>
                    
                    
                    <div className="ms1 flex-col" style={{flexBasis:"50%", justifyContent:"end"}}><h5 className="ban1-letra siged-movt1" style={{marginTop:"0px", fontSize:"24px"}}>Sistema de Información y Gestión Educativa</h5></div>
                    <div className="ms1" style={{flexBasis:"50%"}}><h3 className="ban1-letra siged-movt2" style={{fontSize:"28px", margin:"5px 0 0 0"}}>Validación de documentos académicos electrónicos</h3></div>            
                </div>
        
        
                {/* Cuadro input Folio */}
                <div className="flex-col cuadro-captura ms1" style={{padding:"20px 30px",top: "-65px", justifyContent:"center" ,position: "relative"}}>
                    
                    <div className="flex-row-nw siged-input" style={{gap:"25px"}}>
                        <div className="flex-col" style={{flexBasis:"50%"}}>
                            <div className="div-folio">
                                <input id="buscaFolio" class="i-folio" ng-model="folio" type="text" placeholder="*Folio SEP" style={{fontSize: "18px",background:"rgb(225,225,225)",color:"black"}} required=""/> </div>
                        </div>
                        <div className="flex-row-nw siged-input-bottons" style={{flexBasis:"50%", gap:"25px"}}>
                            <div style={{width:"100%"}} className="siged-botton"><button className="b-buscar" style={{height:"97%" }} onClick={Buscar}>Buscar</button></div>
                            <div style={{width:"100%"}} className="siged-botton"><button className="b-restablecer" style={{height:"97%"}}>Restablecer</button></div>
                        </div>
                    </div>
                    <div className="flex-col" style={{margin:"10px", alignItems:"center", fontSize:"18px"}}>
                        <a className="siged-txtfolio" href="https://siged.sep.gob.mx/SIGED/archivos/UbicaFolio.pdf" style={{textDecoration:"underline", color:"white", fontWeight:"300"}}>Ubica el folio en tu certificado</a>
                    </div>
                    
                </div>
        
                {/* Pequeña barra nav */}
                <div className="siged-navpq flex-row ms1" style={{fontSize:"18px", gap:"10px", marginBottom:"65px", flexWrap:"wrap", lineHeight:"0.9"}}>
                    <div><a style={{color:"black"}} href="https://siged.sep.gob.mx/SIGED/index.html">Inicio</a></div>
                    <div><a className="mayorque" style={{textDecoration:"none"}}></a><a  style={{color:"black"}} href="https://siged.sep.gob.mx/SIGED/index.html#info">Consultas</a></div>
                    <div><a className="mayorque" style={{textDecoration:"none"}} ></a><a style={{color:"black"}} href="https://siged.sep.gob.mx/SIGED/documentos.html">Documentos académicos electrónicos</a></div>
                    
                    
                </div>
        
        
        
                {/* Consulta de documento folio */}
        
                <div className="ms1" style={{padding:"0"}}>
                    <div>
                        <h4 style={{fontSize:"24px", fontWeight:"600", }}>Información no encontrada</h4>
                        <hr className="red"></hr>
        
                    </div>
                    
                    <div style={{marginBottom:"30px"}}>
                        <div style={{textAlign:"center", fontSize:"18px"}} className="alertNotFound">
                            <p style={{fontWeight:"bold", marginBottom:"20px"}}>La información solicitada no se encuentra en nuestros registros.</p>
                            <p style={{marginBottom:"5px"}}>Verifica que hayas escrito bien el FOLIO y vuelve a intentarlo</p>
                            <p style={{lineHeight:"1.3", marginBottom:"30px"}}>Si después de intentarlo nuevamente, el FOLIO no es localizado o si los datos que presenta no coinciden, deberás notificarlo a la autoridad de tu centro educativo</p>
                            <div>
                                <a href="/">
                                <button className="btnAlert" style={{padding:"10px 25px"}}>Volver a intentarlo</button>
                                </a>
                            </div>
                        </div>
                    </div>
        
                </div>
        
        
                
        
            </div>
        )
    }
}





}

