import React from "react";
import { useState } from 'react';
import ban1 from './img/ban11.png'
import datos from './datos.json'

// method: 'GET',
// url: global_uri+'/certificado/consultaCertificado/?folios='+consulta,
// url: 'http://localhost:8080/Core/alumno/curp=' + curps,
// url: 'https://siged.sep.gob.mx/services/AlumnoService/AlumnoServiceRS/alumnos/alumno/' + curps,
// headers: {
//     'Content-Type': 'application/x-www-form-urlencoded',
// }
//https://siged.sep.gob.mx/certificado/consultaCertificado/?folio=3D607306-28A3-42BF-9A6D-78E5DC832BE2




export default function Home(){


    const url = document.URL
    const urlm = document.URL.length - 37
    const urlg = url.substring(urlm)   
    
    const [count, setCount] = useState('');

    
    function actualizarFolio(){
        setCount(document.getElementById('buscaFolio').value) 
        
    }

    function Buscar(){
        
        var folioBuscar = document.getElementById('buscaFolio').value;
        
        if(folioBuscar == ""){
            folioBuscar = document.getElementById('buscaFolio').placeholder;
            
        }
        
        const datosLong = datos.length - 1
        
        for( let i=0; i < datos.length; i++){
            
            
            if(datos[i].folio == count){
               
                window.location.href='./certificados/iems/?folio='+count
                
            }
            if( i == datosLong){
                
                window.location.href= './certificado/iems/?folio='+folioBuscar
            }
           
            
        }
    }

    function restablecerFolio(){
        document.getElementById('buscaFolio').value = ""
    }

    
return(
    <div className="">
        {/* Primer ban */}
        <div style={{backgroundSize: "cover", height:"350px"}}>
                <img src={ban1} className="siged-ban1img"></img>
        </div>
        <div className="flex-col" style={{height:"300px", justifyContent:"center", top: "100px", width:"100%", position:"absolute"}}>
            
            
            <div className="ms1 flex-col" style={{flexBasis:"50%", justifyContent:"end"}}><h5 className="ban1-letra siged-movt1" style={{marginTop:"0px", fontSize:"24px"}}>Sistema de Información y Gestión Educativa</h5></div>
            <div className="ms1" style={{flexBasis:"50%"}}><h3 className="ban1-letra siged-movt2" style={{fontSize:"28px", margin:"5px 0 0 0"}}>Validación de documentos académicos electrónicos</h3></div>            
        </div>


        {/* Cuadro input Folio */}
        <div className="flex-col cuadro-captura ms1" style={{padding:"20px 30px",top: "-65px", justifyContent:"center" ,position: "relative"}}>
            
            <div className="flex-row-nw siged-input" style={{gap:"25px"}}>
                <div className="flex-col" style={{flexBasis:"50%"}}>
                    <div className="div-folio">
                        <input id="buscaFolio" class="i-folio" ng-model="folio" type="text" placeholder="*Folio SEP" style={{fontSize: "18px",background:"rgb(225,225,225)",color:"black"}} required="" onChange={actualizarFolio}/> 
                    </div>
                </div>
                <div className="flex-row-nw siged-input-bottons" style={{flexBasis:"50%", gap:"25px"}}>
                    <div style={{width:"100%"}} className="siged-botton"><button className="b-buscar"  style={{height:"97%"}} onClick={Buscar}>Buscar</button></div>
                    <div style={{width:"100%"}} className="siged-botton"><button className="b-restablecer" style={{height:"97%"}} onClick={restablecerFolio}>Restablecer</button></div>
                </div>
            </div>
            <div className="flex-col" style={{margin:"10px", alignItems:"center", fontSize:"18px"}}>
                <a className="siged-txtfolio" href="https://siged.sep.gob.mx/SIGED/archivos/UbicaFolio.pdf" style={{textDecoration:"underline", color:"white", fontWeight:"300"}}>Ubica el folio en tu certificado</a>
            </div>
            
        </div>

        {/* Pequeña barra nav */}
        <div className="siged-navpq flex-row ms1" style={{fontSize:"18px", gap:"10px", marginBottom:"65px", flexWrap:"wrap", lineHeight:"0.9"}}>
            <div><a style={{color:"black"}} href="https://siged.sep.gob.mx/SIGED/index.html">Inicio</a></div>
            <div><a className="mayorque" style={{textDecoration:"none"}}></a><a  style={{color:"black"}} href="https://siged.sep.gob.mx/SIGED/index.html#info">Consultas</a></div>
            <div><a className="mayorque" style={{textDecoration:"none"}} ></a><a style={{color:"black"}} href="https://siged.sep.gob.mx/SIGED/documentos.html">Documentos académicos electrónicos</a></div>
            
            
        </div>

        {/* div azul leyenda "Los certificados estarán disponibles para su consulta un día después de su emisión.." */}
        <div className="siged-divazul">
            <div>
                <p style={{fontSize:"18px"}}><i className="fa fa-lightbulb" style={{paddingRight:"5px", lineHeight:"0.9"}}></i>Los certificados estarán disponibles para su consulta un día después de su emisión..</p>
            </div>

        </div>
    </div>
)

}

